import { MatDialogConfig } from '@angular/material/dialog';

export class QaroniDialogConfig<D = unknown> extends MatDialogConfig<D> {
  constructor() {
    super();

    this.width = '700px';
    this.maxWidth = '90vw';
    this.panelClass = 'style-close-dialog';
    this.autoFocus = false;
    this.disableClose = true;
  }
}
